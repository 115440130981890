/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */
import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import type { Provider, TransactionRequest } from "@ethersproject/providers";
import type { PromiseOrValue } from "../../../common";
import type {
  FakeZeroEx,
  FakeZeroExInterface,
} from "../../../contracts/tests/FakeZeroEx";

const _abi = [
  {
    inputs: [
      {
        internalType: "uint256",
        name: "wantedETH",
        type: "uint256",
      },
    ],
    name: "fakeSwap",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    stateMutability: "payable",
    type: "receive",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b5060908061001f6000396000f3fe60806040526004361060205760003560e01c8063ecee08d014602b57600080fd5b36602657005b600080fd5b603a6036366004606c565b603c565b005b604051339082156108fc029083906000818181858888f193505050501580156068573d6000803e3d6000fd5b5050565b600060208284031215607c578081fd5b503591905056fea164736f6c6343000804000a";

type FakeZeroExConstructorParams =
  | [signer?: Signer]
  | ConstructorParameters<typeof ContractFactory>;

const isSuperArgs = (
  xs: FakeZeroExConstructorParams
): xs is ConstructorParameters<typeof ContractFactory> => xs.length > 1;

export class FakeZeroEx__factory extends ContractFactory {
  constructor(...args: FakeZeroExConstructorParams) {
    if (isSuperArgs(args)) {
      super(...args);
    } else {
      super(_abi, _bytecode, args[0]);
    }
  }

  override deploy(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): Promise<FakeZeroEx> {
    return super.deploy(overrides || {}) as Promise<FakeZeroEx>;
  }
  override getDeployTransaction(
    overrides?: Overrides & { from?: PromiseOrValue<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  override attach(address: string): FakeZeroEx {
    return super.attach(address) as FakeZeroEx;
  }
  override connect(signer: Signer): FakeZeroEx__factory {
    return super.connect(signer) as FakeZeroEx__factory;
  }

  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): FakeZeroExInterface {
    return new utils.Interface(_abi) as FakeZeroExInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): FakeZeroEx {
    return new Contract(address, _abi, signerOrProvider) as FakeZeroEx;
  }
}
